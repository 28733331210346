import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage193Avaleht from "./pages/Webpage193Avaleht";
import Webpage195Ajurveeda from "./pages/Webpage195Ajurveeda";
import Webpage197Konsultatsioon from "./pages/Webpage197Konsultatsioon";
import Webpage200Ajurveeda_soovitab from "./pages/Webpage200Ajurveeda_soovitab";
import Webpage204Soovitused_kevadeks from "./pages/Webpage204Soovitused_kevadeks";
import Webpage205Soovitused_s__giseks from "./pages/Webpage205Soovitused_s__giseks";
import Webpage206Soovitused_talveks from "./pages/Webpage206Soovitused_talveks";
import Webpage277Ajurveeda_kehat____bid from "./pages/Webpage277Ajurveeda_kehat____bid";
import Webpage315Checkout from "./pages/Webpage315Checkout";
import Webpage316Homepage from "./pages/Webpage316Homepage";
import Webpage317Contact from "./pages/Webpage317Contact";
import Webpage321products from "./pages/Webpage321products";
import Webpage322product from "./pages/Webpage322product";
import Webpage371efsef from "./pages/Webpage371efsef";
import Webpage374sefsef from "./pages/Webpage374sefsef";
import Webpage375megamenu from "./pages/Webpage375megamenu";
import Webpage383cookiepolicy from "./pages/Webpage383cookiepolicy";
import Webpage399sefsef from "./pages/Webpage399sefsef";
import Webpage400TEST_PAGE from "./pages/Webpage400TEST_PAGE";
import Webpage401new_page from "./pages/Webpage401new_page";
import Webpage402tesetsetset from "./pages/Webpage402tesetsetset";
import Webpage403maksa from "./pages/Webpage403maksa";
import Webpage404tooted from "./pages/Webpage404tooted";
import Webpage405single_product from "./pages/Webpage405single_product";
import Webpage406toode from "./pages/Webpage406toode";
import Webpage407testestsete from "./pages/Webpage407testestsete";
import Webpage409FSEFSEF from "./pages/Webpage409FSEFSEF";
import Webpage410english_test from "./pages/Webpage410english_test";
import Webpage411russian_test from "./pages/Webpage411russian_test";
import Webpage412test from "./pages/Webpage412test";
import Webpage413contentrussian from "./pages/Webpage413contentrussian";
import Webpage414teset from "./pages/Webpage414teset";
import Webpage415russian_test from "./pages/Webpage415russian_test";
import Webpage416russian_test from "./pages/Webpage416russian_test";
import Webpage417RUSSIAN from "./pages/Webpage417RUSSIAN";
import Webpage418TEST from "./pages/Webpage418TEST";
import Webpage419test from "./pages/Webpage419test";
import Webpage420test from "./pages/Webpage420test";
import Webpage421russian_website_testing from "./pages/Webpage421russian_website_testing";
import Webpage424notFound from "./pages/Webpage424notFound";
import Webpage4344_test from "./pages/Webpage4344_test";
import Webpage4366_page from "./pages/Webpage4366_page";
import Webpage4381_test from "./pages/Webpage4381_test";
import Webpage440test_1 from "./pages/Webpage440test_1";

var baseURL = 'https://d2.itprojektid.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiYzgzOTMxOWY0MzVkOWQzYjYxMWRjOTEzOGMwMTU1MDY4NWI2ZTE5MzMzMzczODE5MGIwMThmMTNjMDk4N2MzOTNmYzZjYzJlOGRhY2U1MGYiLCJpYXQiOjE3MjY2NDc2NDguMzM2NjQxLCJuYmYiOjE3MjY2NDc2NDguMzM2NjQ2LCJleHAiOjE3MjY3MzQwNDguMzMyMTMyLCJzdWIiOiI2NyIsInNjb3BlcyI6W119.Fou40k7FsCqP6FgBHLD5iNHlLSqzKE1WCi5wGeHQ4ZuDDsxWMe_O68LGiEYiT0sbHqfzb-tSC1IPKKZElXDAmICZG4astttcDHHfIa_vn_zvGu8HWwP9q9541zicDgWKW5v5gEIseJFsxxeOvXtaq4tcLJAtGLJcVYSGhZQxz14GJ-32z9WXiW3Oqze4gzrWWKggkJSjXKPIZwILihCvHvd4BvAMzjh1tYD58SKp0Jw-qrRfmZctQl_HKOF_xUBL2uL3o0fd4vNmqb-xUZwYOBdDvwHGQolHFO1V55J9GsQ-T5spJH8EyBt5wlhf-EFCt9fY9DKoTBdS1V8XXNx0aj-DxzUBVJbSc2KO8CqTy2SRszcr8bjLDsVXU0ISiLXktuiZSycKpKe2BNTam5_xNBnkNLRzGEj1CRc1cnXR9TGkhDS2rkJm94mmsHe5H7iPHR5W_UO4t5O6bLrJwai0B_4EuUC-FmSUgQrEwRyos0hqG7zQ3nff3gatl6iKaSurLQHhj4xAONGOetNEtfCBqbaqZIBEUFFpL4vLSc_o8Zbpx2aJckF7UJzQiN3PZ6q2SovPE7QYbeAdx7BrX57nTDC5EWQon-NMb7K5fADU09O_KdSHA3D2F336FeHOtrb5B3Ya1xvY0I2S_hpB0SbSOsjqrFkKtGTgdsE3roQ36-0',
    'Access-api-key': "eyJpdiI6IkM2Wmx5dzZvNk1JSkd1WHVHblNmaGc9PSIsInZhbHVlIjoiY1BUOHFEbFY0dzl6bWRXVHZKMFdpZz09IiwibWFjIjoiMTkwM2YzMmFhNTQzMGJlZWFjOTJhMjM4MTgxYjJjNDMyNGRmZDkzZTg5YWEyZjUyOTJmNmFlMWNkYmMyNzM0YiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6InFjTWhiVUczVC85TWNGU2Y0RWZFaGc9PSIsInZhbHVlIjoiUjBoclVaSktzSGRDL0p6TUlxYUUyUT09IiwibWFjIjoiZTliOTUxNmYxYzNhMzQwMGNjZGI3MzUzNTExMWRmMWQ5YWQxMTg2NWI2MjkxYmVlNjhiMzNkNzk4Mzk0OGU5NCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WPJNJFNB"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage193Avaleht />} exact />;
                                                                    <Route path="/ru/ayurveda" element={<Webpage195Ajurveeda />} exact />;
                                                                    <Route path="/et/consultationNnn" element={<Webpage197Konsultatsioon />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage200Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/consultationNnn/soovitused-talveks/ayurveda/seasonalSpring" element={<Webpage204Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/consultationNnn/suggestionss" element={<Webpage205Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/consultationNnn/soovitused-talveks" element={<Webpage206Soovitused_talveks />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypesss" element={<Webpage277Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage315Checkout />} exact />;
                                                                    <Route path="/en/" element={<Webpage316Homepage />} exact />;
                                                                    <Route path="/en/contact" element={<Webpage317Contact />} exact />;
                                                                    <Route path="/en/products" element={<Webpage321products />} exact />;
                                                                    <Route path="/en/product/:id" element={<Webpage322product />} exact />;
                                                                    <Route path="/en/sefessefsef" element={<Webpage371efsef />} exact />;
                                                                    <Route path="/en/sefsef" element={<Webpage374sefsef />} exact />;
                                                                    <Route path="/et/" element={<Webpage375megamenu />} exact />;
                                                                    <Route path="/en/cookiePolicyeng" element={<Webpage383cookiepolicy />} exact />;
                                                                    <Route path="/ru/sefse" element={<Webpage399sefsef />} exact />;
                                                                    <Route path="/et/123-test" element={<Webpage400TEST_PAGE />} exact />;
                                                                    <Route path="/et/new" element={<Webpage401new_page />} exact />;
                                                                    <Route path="/et/tsetsetset" element={<Webpage402tesetsetset />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage403maksa />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage404tooted />} exact />;
                                                                    <Route path="/et/single-product" element={<Webpage405single_product />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage406toode />} exact />;
                                                                    <Route path="/et/testesetset" element={<Webpage407testestsete />} exact />;
                                                                    <Route path="/ru/russian" element={<Webpage409FSEFSEF />} exact />;
                                                                    <Route path="/en/english test" element={<Webpage410english_test />} exact />;
                                                                    <Route path="/ru/russian-test" element={<Webpage411russian_test />} exact />;
                                                                    <Route path="/ru/test" element={<Webpage412test />} exact />;
                                                                    <Route path="/ru/contentrussian" element={<Webpage413contentrussian />} exact />;
                                                                    <Route path="/ru/tset" element={<Webpage414teset />} exact />;
                                                                    <Route path="/ru/rusisiabn-test" element={<Webpage415russian_test />} exact />;
                                                                    <Route path="/ru/russian-test" element={<Webpage416russian_test />} exact />;
                                                                    <Route path="/ru/RUSSIAN" element={<Webpage417RUSSIAN />} exact />;
                                                                    <Route path="/ru/TEST" element={<Webpage418TEST />} exact />;
                                                                    <Route path="/ru/test" element={<Webpage419test />} exact />;
                                                                    <Route path="/ru/test" element={<Webpage420test />} exact />;
                                                                    <Route path="/ru/russian-website-testing" element={<Webpage421russian_website_testing />} exact />;
                                                                    <Route path="/et/*" element={<Webpage424notFound />} exact />;
                                                <Route path="*" element={<Webpage424notFound />} exact />;
                                                                    <Route path="/et/4-test" element={<Webpage4344_test />} exact />;
                                                                    <Route path="/et/6-page" element={<Webpage4366_page />} exact />;
                                                                    <Route path="/et/1-test" element={<Webpage4381_test />} exact />;
                                                                    <Route path="/en/test-en-1" element={<Webpage440test_1 />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}